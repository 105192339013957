import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';

@Component({
    selector: 'ms-logo-component',
    templateUrl: './ms-logo.component.html',
    styleUrls: ['./ms-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgOptimizedImage]
})
export class MsLogoComponent {}
