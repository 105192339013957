import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Patient} from '@matchsource/models/search-status';
import {TranslocoPipe} from '@jsverse/transloco';
import {NgIf} from '@angular/common';

@Component({
    selector: 'ms-patient-identification',
    templateUrl: './patient-identification.component.html',
    styleUrls: ['./patient-identification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, TranslocoPipe]
})
export class PatientIdentificationComponent {
  @Input()
  patient: Patient;

  @Input()
  phenotypeId: number;
}
