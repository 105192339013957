import {makeEnvironmentProviders, ENVIRONMENT_INITIALIZER} from '@angular/core';
import {ResetService} from './services/reset.service';

export function noop() {
  return (): void => undefined;
}

export function withNgxsReset() {
  return makeEnvironmentProviders([
    ResetService,
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: noop,
      deps: [ResetService],
      multi: true,
    },
  ]);
}
