import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {TRACE_ID_HEADER_KEY} from '@matchsource/api-utils';
import {
  BasicError,
  BasicErrorHttpResponse,
  CUSTOM_ERROR_HANDLING,
  CustomErrorHandlingHttpContextModel,
  DEFAULT_ERROR_DETAILS,
  DiagnosticsData,
  ErrorDetails,
} from '@matchsource/error-handling/core';
import {
  ERROR_CODE_ZERO,
  ERROR_NOTIFICATION,
  ErrorCodeZeroContextData,
  SILENCE_ERROR,
  SILENCE_RESPONSE_STATUSES,
  THROW_ORIGINAL_ERROR,
} from '@matchsource/core';
import {blobToObject} from '@matchsource/utils';
import {EventService} from '@matchsource/event';

export const HTTP_ERROR_STATUSES = [500, 503];

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private readonly events: EventService) {}

  private handleBasicError(
    request: HttpRequest<any>,
    response: HttpErrorResponse,
    customErrorHandling: CustomErrorHandlingHttpContextModel
  ): Observable<HttpEvent<any>> {
    const traceId = response.headers.get(TRACE_ID_HEADER_KEY);
    const isJsonResponse = response.headers.get('content-type') === 'application/json';
    const handler = from(
      ResponseInterceptor.parseErrorData(response.error, isJsonResponse).then((error: any) => {
        const errorCodeZeroContextData = request.context.get(ERROR_CODE_ZERO);
        this.dispatchErrorNotification({
          traceId,
          customErrorHandling,
          serverErrorCode: response.status === 500 ? error?.message : undefined,
          originalError: error,
          request,
          response,
          errorCodeZeroContextData,
        });
      })
    ).pipe(
      // a custom error handler can return a rejected promise to override the default behavior.
      catchError(() => of()),
      map(() => response)
    );

    if (HTTP_ERROR_STATUSES.includes(response.status)) {
      return handler.pipe(
        switchMap(() => throwError(() => `${response.status} Something is going wrong.Please try again later.`))
      );
    }

    if (response.status === 403) {
      return handler.pipe(switchMap(() => throwError(() => `${response.status} Bad credentials.`)));
    }

    return handler.pipe(switchMap(() => throwError(() => response)));
  }

  private isResponseStatusSilenced(responseStatus: number, silencedResponseStatuses: number[]): boolean {
    return silencedResponseStatuses.includes(responseStatus);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isSilent = request.context.get(SILENCE_ERROR);
    const skipResponseStatuses = request.context.get(SILENCE_RESPONSE_STATUSES) || [];
    const shouldRethrowOriginalError = request.context.get(THROW_ORIGINAL_ERROR);
    const customErrorHandling = request.context.get(CUSTOM_ERROR_HANDLING);

    return next.handle(request).pipe(
      catchError(response => {
        const isStatusSilenced = this.isResponseStatusSilenced(response.status, skipResponseStatuses);
        if (isSilent || isStatusSilenced) {
          return throwError(() => response);
        }

        if (!(response instanceof HttpErrorResponse)) {
          return throwError(() => response);
        }

        const errorCodeZeroContextData = request.context.get(ERROR_CODE_ZERO);

        switch (true) {
          case response.status >= 401 && response.status < 600: {
            const handleResult = this.handleBasicError(request, response, customErrorHandling);
            if (shouldRethrowOriginalError) {
              return throwError(() => response);
            }
            return handleResult;
          }
          case response.status === 400:
            return throwError(() => response);
          // Usually we shouldn't get responses with status 200 in the catchError operator. Due to MS-15844 we do.
          // We get response with status code of 200 and body with html saying When http handler tries to
          // parse the body it expects to be json and since it's not, it throws an error
          case response.status === 200: {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('text/html') >= 0) {
              const supportIdText = 'Your support ID is: ';
              const message = response.error.text;
              if (message.indexOf(supportIdText) >= 0) {
                let supportId = message.split(supportIdText)[1].split('<br>')[0];
                supportId = `ASM ${supportId}`;
                this.dispatchErrorNotification({traceId: supportId, request, response});
                return throwError(() => response);
              }
            }
            break;
          }
          case response.status === 0: {
            this.dispatchErrorNotification({request, response, errorCodeZeroContextData});
            return throwError(() => response);
          }
        }

        return throwError(() => response);
      })
    );
  }

  private static getDiagnosticsData(errorCodeZeroContextData?: ErrorCodeZeroContextData): DiagnosticsData {
    if (!errorCodeZeroContextData) {
      return undefined;
    }

    const diagnosticsData: DiagnosticsData = {
      retryAttempted: errorCodeZeroContextData.retryAttempted,
      networkCheckHttpResponse: errorCodeZeroContextData.networkCheckHttpResponse,
      retryHttpResponse: errorCodeZeroContextData.retryHttpResponse,
    };
    return diagnosticsData;
  }

  private static getErrorDetails(params: {
    customErrorHandling?: CustomErrorHandlingHttpContextModel;
    serverErrorCode?: string;
  }): ErrorDetails {
    const {customErrorHandling, serverErrorCode} = params;
    if (!customErrorHandling) {
      return DEFAULT_ERROR_DETAILS;
    }
    const {singleError, bySystemErrorCode} = customErrorHandling;
    return (
      singleError ||
      (serverErrorCode && bySystemErrorCode && bySystemErrorCode[serverErrorCode]) ||
      DEFAULT_ERROR_DETAILS
    );
  }

  private dispatchErrorNotification(params: DispatchErrorNotificationArg) {
    const {traceId, customErrorHandling, serverErrorCode, request, response, errorCodeZeroContextData} = params;

    const errorDetails = ResponseInterceptor.getErrorDetails({customErrorHandling, serverErrorCode});
    const diagnosticsData = ResponseInterceptor.getDiagnosticsData(errorCodeZeroContextData);
    this.events.dispatch<BasicError>(ERROR_NOTIFICATION, {
      traceId,
      errorCode: errorDetails.errorCode,
      originalError: params?.originalError,
      errorHttpResponse: this.getBasicErrorHttpResponse(request, response),
      diagnosticsData,
    });
  }

  private getBasicErrorHttpResponse(
    request: HttpRequest<any>,
    httpErrorResponse: HttpErrorResponse
  ): BasicErrorHttpResponse {
    return {
      message: httpErrorResponse.message,
      status: httpErrorResponse.status,
      statusText: httpErrorResponse.statusText,
      error: httpErrorResponse.error,
      url: request.urlWithParams,
    };
  }

  private static parseErrorData(data: any, isJsonResponse = false): Promise<any> {
    const isJsonDataType = data.type?.toLowerCase().indexOf('json') > -1;
    if (data instanceof Blob && isJsonDataType) {
      return blobToObject(data);
    }

    // Need this hack because JSON response is not parsed when responseType is 'text' (it is set by ng-openapi-gen for methods with 'void' responses) and application/json content type in response
    if (isJsonResponse && !isJsonDataType && typeof data === 'string') {
      try {
        const parsedData = JSON.parse(data);
        return Promise.resolve(parsedData);
        // eslint-disable-next-line no-empty, @typescript-eslint/no-unused-vars
      } catch (_ex) {}
    }

    return Promise.resolve(data);
  }
}

interface DispatchErrorNotificationArg {
  traceId?: string;
  customErrorHandling?: CustomErrorHandlingHttpContextModel;
  serverErrorCode?: string;
  originalError?: any;
  request: HttpRequest<any>;
  response?: HttpErrorResponse;
  errorCodeZeroContextData?: ErrorCodeZeroContextData;
}
