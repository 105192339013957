import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchParams, SearchType} from '@matchsource/models/search';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {PATIENT_SEARCH_ENTRY_VALIDATOR_KEY, patientSearchEntryValidator} from '../../validators';
import {PATIENT_SEARCH_ID_VALIDATOR_KEY, patientSearchIdValidator} from '../../validators';
import {PATIENT_SEARCH_NAME_VALIDATOR_KEY, patientSearchNameValidator} from '../../validators';
import {PATIENT_SEARCH_LENGTH_VALIDATOR_KEY, patientSearchLengthValidator} from '../../validators';
import {MIN_ID_LENGTH, MIN_NAME_LENGTH, ADULT_SEARCH_LENGTH} from '../../validators/utils';
import {TranslocoPipe} from '@jsverse/transloco';
import {ToggleDropdownComponent} from '../toggle-dropdown/toggle-dropdown.component';
import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {SearchTypes} from '@matchsource/models/search';

const ERROR_ORDERING = [
  PATIENT_SEARCH_ENTRY_VALIDATOR_KEY,
  PATIENT_SEARCH_ID_VALIDATOR_KEY,
  PATIENT_SEARCH_NAME_VALIDATOR_KEY,
  PATIENT_SEARCH_LENGTH_VALIDATOR_KEY,
];

const LOOKUP_PLACEHOLDERS = {
  [SearchTypes.Patient]: 'APP_SEARCH.PLACEHOLDERS.PATIENT',
  [SearchTypes.Donor]: 'APP_SEARCH.PLACEHOLDERS.DONOR',
  [SearchTypes.Cord]: 'APP_SEARCH.PLACEHOLDERS.CORD',
  [SearchTypes.Biobank]: 'APP_SEARCH.PLACEHOLDERS.BIOBANK',
};

@Component({
    selector: 'ms-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FormsModule, ReactiveFormsModule, NgIf, ToggleDropdownComponent, NgSwitch, NgSwitchCase, TranslocoPipe]
})
export class SearchComponent implements OnChanges, OnDestroy {
  @Input()
  searchParams: SearchParams;

  @Input()
  searchTypes: SearchTypes[];

  @Input()
  placeholder: string;

  @Input()
  hideTypesSelect = false;

  @Input()
  isCommonPractice: boolean;

  @Output()
  search: EventEmitter<SearchParams> = new EventEmitter();

  searchTypeItems: SearchType[] = [];

  submitted = false;

  noLongItem = false;

  readonly searchForm: UntypedFormGroup;

  readonly minIdLength = MIN_ID_LENGTH;

  readonly minNameLength = MIN_NAME_LENGTH;

  readonly adultSearchLength = ADULT_SEARCH_LENGTH;

  private readonly destroy$: Subject<void> = new Subject();
  placeHolderValue = 'COMMON.LOOKUP';

  get searchTermControl(): AbstractControl {
    return this.searchForm.get('searchTerm');
  }

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.searchForm = this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('searchTypes' in changes) {
      this.searchTypeItems = this.getSearchItemTypes(this.searchTypes);
      this.noLongItem = !this.searchTypes || !this.searchTypes.includes(SearchTypes.Biobank);
    }

    if ('searchParams' in changes) {
      this.resetForm(this.searchParams, {emitEvent: true});
    }

    if ('hideTypesSelect' in changes) {
      this.resetForm({type: SearchTypes.Patient});
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    this.submitted = true;
    if (!this.searchForm.valid) {
      return;
    }
    const formData = {...this.searchForm.value};
    formData.searchTerm = formData.searchTerm.trim();
    this.search.emit(formData);
  }

  getError() {
    const searchTermControl = this.searchTermControl;

    if (searchTermControl.valid) {
      return null;
    }

    return ERROR_ORDERING.find(error => searchTermControl.hasError(error));
  }

  isNeededToShowError() {
    return this.searchTermControl.invalid && this.submitted;
  }

  private getSearchItemTypes(searchTypes: SearchTypes[]): SearchType[] {
    return searchTypes.map<SearchType>(searchType => ({
      value: searchType,
      text: `COMMON.${searchType.toUpperCase()}`,
    }));
  }

  private createForm(): UntypedFormGroup {
    const form = this.formBuilder.group(
      {
        searchTerm: [
          '',
          {
            updateOn: 'submit',
          },
        ],
        type: [null],
      },
      {
        updateOn: 'change',
      }
    );

    form
      .get('type')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(type => {
        this.placeHolderValue = this.placeholder || this.generatePlaceHolder(type);
        this.resetForm({type});
      });

    this.setFormValidators(form);

    return form;
  }

  private setFormValidators(form: UntypedFormGroup) {
    const searchTermControl = form.get('searchTerm');
    const searchType = form.get('type').value;

    if (searchType === SearchTypes.Patient) {
      searchTermControl.setValidators([
        patientSearchEntryValidator,
        patientSearchIdValidator,
        patientSearchNameValidator,
      ]);
    } else if (searchType === SearchTypes.Biobank) {
      searchTermControl.setValidators([patientSearchLengthValidator]);
    } else {
      searchTermControl.setValidators(null);
    }

    searchTermControl.updateValueAndValidity();
  }

  private resetForm(value: Partial<SearchParams>, options?: {emitEvent: boolean}) {
    this.submitted = false;
    if (value) {
      this.searchForm.patchValue(value, options);
    }

    this.setFormValidators(this.searchForm);
  }

  private generatePlaceHolder(type: string): string {
    return LOOKUP_PLACEHOLDERS[type];
  }
}
