import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {AuthService} from '@matchsource/authentication';
import {map, switchMap} from 'rxjs/operators';

/**
 * Add bearer token to all requests
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token$ = from(this.auth.getAccessToken());
    return token$.pipe(
      map(token => {
        const headers = request.headers.set('Authorization', `Bearer ${token}`);

        return request.clone({
          headers,
        });
      }),
      switchMap(authRequest => next.handle(authRequest))
    );
  }
}
