import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../../environments/environment';
import {
  BusinessPartiesState,
  BusinessPartiesNotEMDISState,
  CuratedSearchState,
} from '@matchsource/store/business-parties';
import {CountriesState} from '@matchsource/store/countries';
import {CountriesStatesState} from '@matchsource/store/countries-states';
import {NgxsUiRouterPluginModule} from 'ngxs-ui-router';
import {withNgxsReset} from 'app/ngxs-reset/ngxs-reset.module';
import {CoordinatorsState} from '@matchsource/store/coordinators';
import {RacesState} from '@matchsource/store/races';
import {EthnicityState} from '@matchsource/store/ethnicity';
import {SecondaryCodesState} from '@matchsource/store/secondary-codes';
import {SearchesState} from '@matchsource/store/searches';
import {CmvState} from '@matchsource/store/cmv';
import {PopulationRacesState} from '@matchsource/store/population-races';
import {PermissionsState} from '@matchsource/store/permissions';
import {AutoSaveState} from '@matchsource/store/auto-save';
import {LanguagesState} from '@matchsource/store/languages';
import {TransplantTimelineState} from '@matchsource/store/transplant-timeline';
import {PreferredProductsState} from '@matchsource/store/preferred-products';
import {BloodTypesState} from '@matchsource/store/blood-types';
import {CartState} from '@matchsource/store/cart';
import {SuppliersState} from '@matchsource/store/suppliers';
import {CloseReasonsState} from '@matchsource/store/close-reasons';
import {MatchPreferencesState} from '@matchsource/store/match-preferences';
import {CcrState} from '@matchsource/store/ccr';
import {OrderAttachmentsState} from '@matchsource/store/attachments';
import {DsaState} from '@matchsource/store/dsa';
import {DialogsState} from '@matchsource/store/dialogs';
import {SourceHlaHistoriesState} from '@matchsource/store/source-hla-histories';
import {SearchStatesState} from '@matchsource/store/search-states';
import {WorkupsInProgressState} from '@matchsource/store/workup';
import {VaccinationsState} from '@matchsource/store/vaccinations';
import {WorkupListState} from '@matchsource/store/workup-list';
import {DonorWorkupInProgressState} from '@matchsource/store/donor-workup-in-progress';
import {KitOrderLabRefsStateState} from '@matchsource/store/kit-order-lab-refs';
import {SmartyStretsAutocompleteState, SmartyStreetsValidationState} from '@matchsource/store/smarty-streets';
import {RelatedFamilyMembersAddressesState} from '@matchsource/store/related-family-members';
import {AnnouncementsState} from '@matchsource/store/announcements';
import {SpinnerState} from '@matchsource/spinner';
import {PatientStatusState} from '@matchsource/store/patient-status';
import {LociState} from '@matchsource/store/loci';
import {
  PatientActualPTRState,
  PatientCloseHistoryState,
  PatientState,
  PatientTransferHistoryState,
  PatientTransplantTimelineHistoryState,
} from '@matchsource/store/patient';
import {DiseasesState} from '@matchsource/store/diseases';
import {SourceEthnicityRacesState} from '@matchsource/store/source-ethnicity-races';
import {GendersState} from '@matchsource/store/genders';
import {IdentityVerificationState} from '@matchsource/store/identity-verification';
import {DiseaseStagesState} from '@matchsource/store/disease-stages';
import {ClinicalRationaleState} from '@matchsource/store/clinical-rationale';
import {OplState} from 'app/features/search-results/shared/store/opl/opl.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        AnnouncementsState,
        BusinessPartiesState,
        BusinessPartiesNotEMDISState,
        CuratedSearchState,
        SpinnerState,
        CountriesState,
        CountriesStatesState,
        CartState,
        CoordinatorsState,
        RacesState,
        EthnicityState,
        SecondaryCodesState,
        SearchesState,
        DiseasesState,
        CmvState,
        CcrState,
        PopulationRacesState,
        SourceEthnicityRacesState,
        LanguagesState,
        TransplantTimelineState,
        DiseaseStagesState,
        PreferredProductsState,
        PermissionsState,
        AutoSaveState,
        BloodTypesState,
        LociState,
        SuppliersState,
        IdentityVerificationState,
        MatchPreferencesState,
        CloseReasonsState,
        OrderAttachmentsState,
        DsaState,
        DialogsState,
        SourceHlaHistoriesState,
        SearchStatesState,
        WorkupsInProgressState,
        OplState,
        VaccinationsState,
        WorkupListState,
        DonorWorkupInProgressState,
        GendersState,
        PatientState,
        PatientCloseHistoryState,
        PatientTransferHistoryState,
        PatientTransplantTimelineHistoryState,
        PatientActualPTRState,
        KitOrderLabRefsStateState,
        SmartyStretsAutocompleteState,
        SmartyStreetsValidationState,
        PatientStatusState,
        RelatedFamilyMembersAddressesState,
        ClinicalRationaleState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: true,
        },
        compatibility: {
          strictContentSecurityPolicy: true,
        },
      }
    ),
    NgxsUiRouterPluginModule,
    environment.ngxsPlugins,
  ],
  providers: [withNgxsReset()],
  exports: [NgxsModule],
})
export class StoreModule {}
