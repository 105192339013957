import {Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {DuplicateService} from '../../services/duplicate.service';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {Actions, ofActionDispatched} from '@ngxs/store';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {UIRouterModule} from '@uirouter/angular';
import {NgIf, AsyncPipe} from '@angular/common';
import {ClearPatient, PatientService} from '@matchsource/store/patient';

@Component({
    selector: 'ms-patient-container',
    templateUrl: './patient-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DuplicateService],
    imports: [NgIf, UIRouterModule, AsyncPipe]
})
export class PatientContainerComponent implements OnDestroy {
  readonly isVisible$: Observable<boolean>;

  private readonly ready$ = new BehaviorSubject(true);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly patientService: PatientService,
    private readonly actions$: Actions,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.actions$.pipe(ofActionDispatched(ClearPatient), take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.ready$.next(false);
    });

    this.isVisible$ = combineLatest([this.patientService.loaded$, this.ready$]).pipe(
      map(([loaded, ready]) => loaded && ready),
      tap(() => this.cdRef.detectChanges())
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.ready$.complete();
  }
}
